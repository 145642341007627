/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Button, Typography } from "@mui/material";
import { ArrowRight } from "react-feather";
import EmailField from "../Inputs/EmailField";
import FirstNameField from "../Inputs/FirstNameField";
import LastNameField from "../Inputs/LastNameField";
import DobField from "../Inputs/DobField";
import PasswordField from "../Inputs/PasswordField";
import ConfirmPasswordField from "../Inputs/ConfirmPasswordField";
import ReferralCodeField from "../Inputs/ReferralCodeField";

import { STATUS_OPTIONS } from "../AcceptInvite/helpers";

const StyledButton = styled(Button)`
  border: 1px solid black;
  color: black;
`;

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  firstName: Yup.string()
    .required("First name is required")
    .min(2, "Minimum 2 characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .min(2, "Minimum 2 characters"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password should be at least 8 characters"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "Those passwords didn't match. Try again."
    )
    .required("One more time, just to be sure!")
    .min(8, "Password should be at least 8 characters"),
  dob: Yup.number().required("Date of birth is required"),
  ref: Yup.string(),
});

const Subtitle = styled(Typography)`
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  margin-bottom: 12px;
  margin-top: 0px;
  color: black;
  align-self: center;
  text-align: center;
`;

const FormOne = ({ dispatch, formState, ACTIONS }) => {
  const { confirmPassword, firstName, lastName, email, password, ref, dob } =
    formState;

  const [emailStatus, setEmailStatus] = useState(STATUS_OPTIONS.UNKNOWN);
  const [referralStatus, setReferralStatus] = useState(STATUS_OPTIONS.UNKNOWN);

  const handleFormSubmit = (values) => {
    dispatch({
      type: ACTIONS.COMPLETE_FIRST_STEP,
      payload: {
        firstName: values.firstName,
        lastName: values.lastName,
        ref: values.ref,
        countryCode: values.countryCode,
        email: values.email,
        password: values.password,
        dob: values.dob,
        confirmPassword: values.confirmPassword,
      },
    });
  };

  const getIsFormComplete = (errors, values) => {
    const hasNoErrors = Object.values(errors).length === 0;
    const allValuesInputted = Boolean(
      values.confirmPassword &&
        values.dob &&
        values.email &&
        values.firstName &&
        values.lastName &&
        values.password
    );

    return (
      hasNoErrors &&
      allValuesInputted &&
      emailStatus === STATUS_OPTIONS.CONFIRMED
    );
  };

  const InitialValues = {
    firstName: firstName || "",
    lastName: lastName || "",
    email: email || "",
    password: password || "",
    confirmPassword: confirmPassword || "",
    dob: dob || "",
    ref: ref || "",
  };

  return (
    <Formik
      initialValues={InitialValues}
      validationSchema={ValidationSchema}
      onSubmit={handleFormSubmit}
    >
      {({
        errors,
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Subtitle>--- Account Information ---</Subtitle>
          <EmailField
            values={values}
            touched={touched}
            handleChange={handleChange}
            errors={errors}
            handleBlur={handleBlur}
            verifyEmail
            emailStatus={emailStatus}
            setEmailStatus={setEmailStatus}
          />
          <PasswordField
            values={values}
            touched={touched}
            handleChange={handleChange}
            errors={errors}
            handleBlur={handleBlur}
          />
          <ConfirmPasswordField
            values={values}
            touched={touched}
            handleChange={handleChange}
            errors={errors}
            handleBlur={handleBlur}
          />
          <Subtitle>--- Personal Information ---</Subtitle>
          <FirstNameField
            values={values}
            touched={touched}
            handleChange={handleChange}
            errors={errors}
            handleBlur={handleBlur}
          />
          <LastNameField
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <DobField
            setFieldValue={setFieldValue}
            touched={touched}
            errors={errors}
            values={values}
          />
          <ReferralCodeField
            touched={touched}
            errors={errors}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setReferralStatus={setReferralStatus}
            referralStatus={referralStatus}
            verifyCode
          />
          <StyledButton
            type="submit"
            variant="outlined"
            color="primary"
            fullWidth
            disabled={!getIsFormComplete(errors, values)}
            sx={{ p: 1, fontSize: 14, mb: 2 }}
            endIcon={<ArrowRight height={16} width={16} />}
          >
            Continue
          </StyledButton>
        </form>
      )}
    </Formik>
  );
};

export default FormOne;

FormOne.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  ACTIONS: PropTypes.object.isRequired,
};
