/* eslint-disable */
import {
  Alert,
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
  Typography,
  Modal,
} from "@mui/material";
import { format } from "date-fns";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Briefcase } from "react-feather";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import LOGO from "../../../assets/main-logo.webp";
import API from "../../../axios/API";
import PublicFormLayout from "../../../layouts/PublicFormLayout";
import { CustomTextField } from "../../../styles/forms";
import { useNavigate } from "react-router-dom";
import WARNING_IMG from "../../../assets/svg/warning.svg";
import ConfirmPasswordField from "../Inputs/ConfirmPasswordField";
import CountryAndStateFields from "../Inputs/CountryAndStateFields";
import DobField from "../Inputs/DobField";
import EmailField from "../Inputs/EmailField";
import FirstNameField from "../Inputs/FirstNameField";
import LastNameField from "../Inputs/LastNameField";
import LocationSelect from "../Inputs/LocationSelect";
import PasswordField from "../Inputs/PasswordField";
import RoleSelect from "../Inputs/RoleSelect";
import {
  INVITATION_TYPES_V2,
  INVITATTION_TYPES_NEW_USER,
  STATUS_OPTIONS,
  getAcceptInviteFailedErrorMsg,
  getInitialValues,
  getInviteDataFailedErrorMsg,
  getInviteType,
  getValidationSchema,
} from "./helpers";
import ErrorModal from "./ErrorModal";

const Wrapper = styled(Paper)`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 20px;
  position: relative;

  @media (max-width: 420px) {
    width: 96vw;
    padding: 10px;
    min-width: 300px;
  }
`;

const Subtitle = styled(Typography)`
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  margin-bottom: 12px;
  margin-top: 8px;
  color: black;
  align-self: center;
  text-align: center;
`;

const CustomTextFieldUppercase = styled(CustomTextField)`
  input {
    text-transform: uppercase;
  }
`;

const StyledButton = styled(Button)`
  border: 1px solid black;
  color: black;
`;

const formatDateForApi = (dob) => format(new Date(dob), "yyyy-MM-dd");

const getIsAcceptDisabled = (values, errors, inviteType) => {
  const hasErrors = Object.keys(errors).length >= 1;
  const anyValuesMissingEntries = Object.values(values).some(
    (val) => !Boolean(val)
  );

  const needsRoles = [
    INVITATION_TYPES_V2.EXISTING_USER_NO_ROLES,
    INVITATION_TYPES_V2.NEW_USER_NO_ROLES,
  ].includes(inviteType);

  const roleAreSelected = values.roles.length > 0;

  if (hasErrors || anyValuesMissingEntries || (needsRoles && !roleAreSelected))
    return true;
  return false;
};

const AcceptInvite = () => {
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [inviteType, setInviteType] = useState(null);
  const [errorLoadingInvite, setErrorLoadingInvite] = useState(false);

  const [error, setError] = useState(null);
  const [emailStatus, setEmailStatus] = useState(STATUS_OPTIONS.UNKNOWN);
  const navigate = useNavigate();

  const getInviteData = async () => {
    try {
      setIsLoading(true);
      const { data: apiData } = await API.get(
        `/common/invitation/details/?token=${token}`
      );

      setData(apiData);
      const typeOfInvite = getInviteType(apiData);
      setInviteType(typeOfInvite);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const errorMessageObj = getInviteDataFailedErrorMsg(error);
      setErrorLoadingInvite(errorMessageObj);
      return;
    }
  };

  useEffect(() => {
    getInviteData();
  }, []);

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setError(null);
    let sendBody;

    if (inviteType === INVITATION_TYPES_V2.EXISTING_USER_NO_ROLES) {
      sendBody = {
        token,
        team_member_role_ids: values.roles,
      };
    }

    if (inviteType === INVITATION_TYPES_V2.EXISTING_USER_WITH_ROLES) {
      sendBody = {
        token,
      };
    }

    if (inviteType === INVITATION_TYPES_V2.NEW_USER_NO_ROLES) {
      sendBody = {
        token,
        team_member_role_ids: values.roles,
        password: values.password,
        first_name: values.firstName,
        last_name: values.lastName,
        birth_date: formatDateForApi(values.dob),
        country: values.country,
        state: values.state,
      };
    }

    if (inviteType === INVITATION_TYPES_V2.NEW_USER_WITH_ROLES) {
      sendBody = {
        token,
        password: values.password,
        first_name: values.firstName,
        last_name: values.lastName,
        birth_date: formatDateForApi(values.dob),
        country: values.country,
        state: values.state,
      };
    }
    try {
      await API.post("/common/invitation/details/", sendBody);
      navigate("/signin", { state: { fromInvite: true, email: values.email } });
      setSubmitting(false);
    } catch (error) {
      const errorObj = getAcceptInviteFailedErrorMsg(error);
      setError(errorObj);
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <PublicFormLayout>
        <Wrapper style={{ minHeight: 400 }}>
          <Typography variant="h2" sx={{ mt: 2 }} align="center">
            Accept Invite
          </Typography>
          <div
            style={{
              width: "100%",
              height: 280,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={26} style={{ color: "darkgrey" }} />
          </div>
        </Wrapper>
      </PublicFormLayout>
    );
  }

  if (errorLoadingInvite) {
    return (
      <PublicFormLayout>
        <Wrapper
          style={{ minHeight: 340, display: "flex", alignItems: "center" }}
        >
          <Typography variant="h2" sx={{ mt: 2 }} align="center">
            Accept Invite
          </Typography>
          <img
            src={WARNING_IMG}
            style={{ height: 120, width: 120, marginTop: 20 }}
          />
          <Alert severity="error" sx={{ mt: 8, width: "100%" }}>
            <p style={{ fontWeight: "600" }}>{errorLoadingInvite?.title}</p>
            <p>{errorLoadingInvite?.body}</p>
            {errorLoadingInvite?.context && (
              <pre
                style={{
                  fontFamily: "inherit",
                  textAlign: "start",
                  overflowX: "auto",
                  whiteSpace: "pre-wrap",
                }}
              >
                {errorLoadingInvite?.context}
              </pre>
            )}
          </Alert>
        </Wrapper>
      </PublicFormLayout>
    );
  }

  const InitialValues = getInitialValues(inviteType, data);
  const ValidationSchema = getValidationSchema(inviteType);

  const hasInfoMessage = data?.info_messages?.length > 0;

  return (
    <PublicFormLayout>
      <Wrapper>
        <Typography
          sx={{
            mt: 0,
            fontSize: 20,
            fontWeight: "600",
            color: "black",
            textTransform: "uppercase",
          }}
          align="center"
        >
          {data.company}
        </Typography>
        <div
          style={{
            width: "100%",
            background: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 8,
          }}
        >
          <Typography
            variant="body1"
            align="center"
            sx={{ color: "black", fontWeight: 300 }}
          >
            Has invited you to join them on
          </Typography>
          <img
            style={{
              height: 14,
              objectFit: "contain",
              marginLeft: 6,
              marginTop: 2,
            }}
            src={LOGO}
          />
        </div>
        {hasInfoMessage && (
          <Alert severity="info">
            {data?.info_messages?.map((msg) => (
              <p style={{ fontSize: 12, fontWeight: "500" }}>{msg}</p>
            ))}
          </Alert>
        )}
        <Formik
          initialValues={InitialValues}
          validationSchema={ValidationSchema}
          enableReinitialize
          onSubmit={handleFormSubmit}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {INVITATTION_TYPES_NEW_USER.includes(inviteType) ? (
                <Subtitle> --- Create Account Information --- </Subtitle>
              ) : (
                <Subtitle> --- Confirm Account Information --- </Subtitle>
              )}
              <EmailField
                values={values}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                disabled
                emailStatus={emailStatus}
                setEmailStatus={setEmailStatus}
                verifyEmail={false}
              />
              {INVITATTION_TYPES_NEW_USER.includes(inviteType) && (
                <>
                  <PasswordField
                    values={values}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                  />

                  <ConfirmPasswordField
                    values={values}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                  />
                </>
              )}
              {INVITATTION_TYPES_NEW_USER.includes(inviteType) && (
                <>
                  <Subtitle> --- Personal Information --- </Subtitle>
                  <FirstNameField
                    values={values}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                  />
                  <LastNameField
                    values={values}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                  />
                  <DobField
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                </>
              )}
              {INVITATTION_TYPES_NEW_USER.includes(inviteType) && (
                <CountryAndStateFields
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              )}
              <div style={{ height: 8 }} />
              <Subtitle> --- Team Information --- </Subtitle>
              <CustomTextFieldUppercase
                value={data.company}
                label="Company"
                variant="outlined"
                size="medium"
                disabled
                fullWidth
                style={{
                  fontWeight: "600",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Briefcase style={{ color: "darkgrey" }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  mb: 4,
                }}
              />
              <LocationSelect
                values={values}
                touched={touched}
                errors={errors}
                handleChange={handleChange}
                onBlur={handleBlur}
                locations={data.locations}
                disabled={data.locations.length === 1}
              />
              <RoleSelect
                roles={
                  [
                    INVITATION_TYPES_V2.NEW_USER_WITH_ROLES,
                    INVITATION_TYPES_V2.EXISTING_USER_WITH_ROLES,
                  ].includes(inviteType)
                    ? data.roles
                    : data?.available_roles
                }
                disabled={[
                  INVITATION_TYPES_V2.NEW_USER_WITH_ROLES,
                  INVITATION_TYPES_V2.EXISTING_USER_WITH_ROLES,
                ].includes(inviteType)}
                values={values}
                touched={touched}
                errors={errors}
                handleChange={handleChange}
                onBlur={handleBlur}
              />
              <StyledButton
                color="primary"
                type="submit"
                variant="outlined"
                fullWidth
                size="small"
                sx={{ mt: 4 }}
                disabled={getIsAcceptDisabled(values, errors, inviteType)}
              >
                {isSubmitting ? "..." : "Accept Invite"}
              </StyledButton>
            </form>
          )}
        </Formik>
        <Modal open={Boolean(error)} onClose={() => setError(null)}>
          <div>
            <ErrorModal error={error} handleClose={() => setError(null)} />
          </div>
        </Modal>
      </Wrapper>
    </PublicFormLayout>
  );
};

export default AcceptInvite;
