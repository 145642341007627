/* eslint-disable */
import React from "react";
import { Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components/macro";
import DisplayModuleTypeChip from "../DisplayModuleTypeChip";
import { FEED_SCROLL_POSITION_STORAGE_KEY } from "../../../../constants/common";

const CardWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.background.paper};
  text-align: center;
  margin: 6px 8px 12px 8px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  border: 1px solid lightgray;
  height: 300px;
  width: 262px;

  transition: box-shadow 300ms ease-in;

  &:hover {
    box-shadow: 0px 0px 8px 2px lightgray;
  }
`;

const ModTitleContainer = styled.div`
  width: 260px;
  max-width: 260px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 2px 8px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-color: ${(props) => props.theme.palette.nav.background};
`;

const ModuleImage = styled(LazyLoadImage)`
  object-fit: contain;
  height: 260px;
  width: 260px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: white;
`;

const Hidden = styled.div`
  position: absolute;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 19;
  opacity: 0;
  transition: opacity 300ms ease-in;

  &:hover {
    opacity: 1;
  }
`;

const LearnButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 4px;
  padding: 4px 14px;
  color: white;
  cursor: pointer;
  z-index: 20;
  font-size: 16px;
  background-color: ${(props) => props.theme.palette.primary.main};

  &:hover {
    border: 1px solid white;
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const ModuleCardV3 = ({ mod, navigate, getNextModuleInfo }) => {
  const handleClick = (e) => {
    const nextModuleInfo = getNextModuleInfo(mod.id);

    const newScrollPosition = e.pageY - 400 > 0 ? e.pageY - 400 : 0;
    sessionStorage.setItem(FEED_SCROLL_POSITION_STORAGE_KEY, newScrollPosition);

    return navigate(`/moduleV2/${mod.id}`, {
      state: { nextModule: nextModuleInfo },
    });
  };

  return (
    <CardWrapper>
      <Hidden>
        <LearnButton onClick={handleClick}>Start Module</LearnButton>
      </Hidden>
      <ModuleImage src={mod.image} alt="module" />
      <ModTitleContainer>
        <Typography
          style={{
            fontWeight: "500",
            fontSize: 13,
            lineHeight: 1.4,
            color: "black",
            maxWidth: 266,
            overflowY: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {mod.name}
        </Typography>
      </ModTitleContainer>
      <DisplayModuleTypeChip isAssigned={mod.assigned} />
    </CardWrapper>
  );
};

export default ModuleCardV3;

ModuleCardV3.propTypes = {
  mod: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};
