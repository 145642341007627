/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Button, Typography } from "@mui/material";
import useAuth from "../../../store/auth";

const Wrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
`;

const SubtitleSmall = styled.span`
  font-size: 10px;
  color: ${(props) => props.theme.palette.grey[500]};
  margin-top: 4px;
  margin-bottom: -2px;
`;

const LogoutButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.background.bw};
  font-size: 15px;
  color: ${(props) => props.theme.palette.background.wb};
`;

const HoldTeam = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 0px;

  img {
    height: 18px;
    width: 18px;
    border-radius: 3px;
    object-fit: contain;
    margin-right: 4px;
    background-color: white;
    border: 1px solid #eeeeee;
  }

  p {
    margin-right: auto;
  }

  span {
    margin-left: auto;
    font-size: 10px;
    margin-left: 2px;
  }
`;

const ProfileOpen = ({ data }) => {
  const { logout } = useAuth();
  const { profile } = data;

  return (
    <Wrapper>
      <Typography variant="subtitle1" align="center">
        User Information
      </Typography>
      <SubtitleSmall>Email</SubtitleSmall>
      <Typography variant="body1">{profile.email}</Typography>
      <SubtitleSmall>Full Name</SubtitleSmall>
      <Typography variant="body1">
        {`${profile.first_name} ${profile.last_name}`}
      </Typography>
      <SubtitleSmall>Preferred Language</SubtitleSmall>
      <Typography variant="body1" style={{ textTransform: "capitalize" }}>
        {profile.language}
      </Typography>
      <SubtitleSmall>{data.teams.length > 1 ? "Teams" : "Team"}</SubtitleSmall>
      {data.teams.length === 0 ? (
        <Typography variant="body1">No Team 😞</Typography>
      ) : (
        data.teams.map((team) => (
          <HoldTeam>
            <img src={team.company__logo} alt="" />
            <Typography
              style={{
                fontSize: 10,
                fontWeight: 500,
                textTransform: "uppercase",
                color: "darkgrey",
              }}
              noWrap
            >
              {team.company__name}
            </Typography>
            {team.approved ? (
              <div
                style={{
                  height: 9,
                  width: 9,
                  borderRadius: 7,
                  backgroundColor: "mediumseagreen",
                }}
              />
            ) : (
              <div
                style={{
                  height: 9,
                  width: 9,
                  borderRadius: 7,
                  backgroundColor: "darkorange",
                }}
              />
            )}
          </HoldTeam>
        ))
      )}
      <LogoutButton
        fullWidth
        variant="contained"
        sx={{ p: 0, mt: 3 }}
        onClick={() => logout()}
      >
        Logout
      </LogoutButton>
    </Wrapper>
  );
};

export default ProfileOpen;

ProfileOpen.propTypes = {
  data: PropTypes.object.isRequired,
};
