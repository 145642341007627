/* eslint-disable */
import React from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import styled from "styled-components/macro";

const StyledMenuItem = styled(MenuItem)`
  font-weight: 500;
`;

const Subheader = styled.div`
  padding: 4px;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  background: #eeeeee40;
  padding-left: 12px;

  span {
    color: slategrey;
    font-size: 13px;
    font-weight: 500;
  }
`;

const MODULE_TYPES = {
  ALL: "all",
  ASSIGNED: "assigned",
  EXTRA_CREDIT: "extraCredit",
};

const FilterBrandsSelect = ({
  companyFilter,
  setCompanyFilter,
  allTeams,
  allBrands,
  showModules,
  fullWidth = false,
}) => {
  const initialValue = { id: 0, name: "All" };

  return (
    <FormControl
      disablePortal
      style={{
        width: fullWidth ? "100%" : 180,
        height: 30,
        background: "white",
        padding: 0,
        borderRadius: 4,
      }}
      color="primary"
      size="small"
    >
      <Select
        sx={{
          "& .MuiSelect-select": {
            padding: 1,
            height: 24,
            borderRadius: 4,
          },
        }}
        MenuProps={{ disablePortal: fullWidth }}
        value={companyFilter}
        onChange={(e) => setCompanyFilter(e.target.value)}
        renderValue={(value) => {
          if (value.id === 0) {
            return (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  alignSelf: "center",
                  height: 26,
                  gap: 4,
                  overflow: "hidden",
                  paddingLeft: 8,
                }}
              >
                <span style={{ fontWeight: "500" }}>All Teams + Brands</span>
              </div>
            );
          }

          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                alignSelf: "center",

                gap: 6,
                overflow: "hidden",
              }}
            >
              <img
                style={{
                  height: 24,
                  width: 26,
                  borderRadius: 4,
                  objectFit: "contain",
                  border: "1px solid #eeeeee",
                  background: "white",
                }}
                src={value?.logo}
              />
              <span style={{ fontWeight: "500" }}>{value?.name}</span>
            </div>
          );
        }}
      >
        <StyledMenuItem value={initialValue}>
          <span style={{ fontWeight: "600" }}>All Teams + Brands</span>
        </StyledMenuItem>
        {allTeams?.length > 0 && (
          <Subheader>
            <span>Teams</span>
          </Subheader>
        )}
        {allTeams.map((company) => (
          <MenuItem
            key={company?.id}
            value={company}
            disabled={showModules === MODULE_TYPES.EXTRA_CREDIT}
            style={{
              background:
                company?.id === companyFilter?.id ? "#eeeeee" : "white",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 4,
              }}
            >
              <img
                style={{
                  height: 28,
                  width: 28,
                  borderRadius: 4,
                  objectFit: "contain",
                  border: "1px solid #eeeeee",
                  background: "white",
                }}
                src={company?.logo}
              />
              <span
                style={{
                  fontWeight: "500",
                }}
              >
                {company?.name}
              </span>
            </div>
          </MenuItem>
        ))}
        {allBrands?.length > 0 && (
          <Subheader>
            <span>Brands</span>
          </Subheader>
        )}
        {allBrands.map((company) => (
          <MenuItem
            key={company?.id}
            value={company}
            disabled={showModules === MODULE_TYPES.ASSIGNED}
            style={{
              background:
                company?.id === companyFilter?.id ? "#eeeeee" : "white",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",

                gap: 4,
              }}
            >
              <img
                style={{
                  height: 28,
                  width: 28,
                  borderRadius: 4,
                  objectFit: "contain",
                  border: "1px solid #eeeeee",
                  background: "white",
                }}
                src={company?.logo}
              />
              <span
                style={{
                  fontWeight: "500",
                }}
              >
                {company?.name}
              </span>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterBrandsSelect;
