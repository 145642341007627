/* eslint-disable */
import { lightFormat } from "date-fns";
import styled from "styled-components/macro";
import { CheckCircle } from "react-feather";

const FORMAT_STRING = "yyyy-MM-dd";
export const TERMS_HREF = "https://www.withspiffy.com/terms-and-conditions/";

export const formatDateForApi = (date) => lightFormat(date, FORMAT_STRING);

export const CheckCircleGreen = styled(CheckCircle)`
  color: ${(props) => props.theme.palette.success.light};
`;
