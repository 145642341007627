/* eslint-disable */
import React from "react";
import styled, { keyframes } from "styled-components/macro";
import { Paper } from "@mui/material";
import COIN from "../../assets/coinAlone.webp";
import { motion } from "framer-motion";

const WrapperTwo = styled(Paper)`
  height: 280px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #eeeeee;
  border-radius: 28px;
`;

const CoinImage = styled.img`
  height: 100px;
  width: 100px;
  object-fit: contain;
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader = () => {
  return (
    <WrapperTwo>
      <CoinImage
        as={motion.img}
        src={COIN}
        animate={{ rotate: 360 }}
        transition={{ duration: 1.4, repeat: Infinity }}
      />
      <motion.span
        style={{
          fontWeight: 300,
          color: "darkgrey",
          letterSpacing: 3,
          marginTop: 16,
          textTransform: "uppercase",
        }}
        animate={{ opacity: [0.4, 1, 0.4] }}
        transition={{ duration: 1.4, repeat: Infinity }}
      >
        loading page...
      </motion.span>
    </WrapperTwo>
  );
};

const PageLoader = () => (
  <Wrapper>
    <Loader />
  </Wrapper>
);

export default PageLoader;
