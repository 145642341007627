/* eslint-disable */
import { BookOpen, Mail, User, Compass, Repeat, Send } from "react-feather";

const sidebarRoutes = [
  {
    name: "Learn",
    icon: BookOpen,
    url: "/",
    children: false,
  },

  {
    name: "Explore",
    icon: Compass,
    url: "explore",
    children: false,
  },
  {
    name: "Review",
    children: false,
    url: "review",
    icon: Repeat,
  },
  {
    name: "Profile",
    icon: User,
    url: "/profile",
    children: false,
  },
  {
    name: "Invite a friend",
    children: false,
    url: "invite",
    icon: Send,
  },
  {
    name: "Contact us",
    children: false,
    url: "contact",
    icon: Mail,
  },
];

export default sidebarRoutes;
