/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import { Paper, Typography } from "@mui/material";
import Robot from "../../assets/robottwo.webp";

const Centered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
`;

const ErrorPaper = styled(Paper)`
  padding: 20px;
  width: 380px;
  height: 420px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.palette.background.paper};
`;

const HoldRobot = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -16px;
`;

const RobotImg = styled.img`
  height: auto;
  width: 220px;
  object-fit: contain;
  margin-left: 20px;
  transform: translateX(-4px);
`;

const BigOops = styled(Typography)`
  font-size: 50px;
  font-weight: 700;
  color: black;
`;

const PageError = ({
  title = "We had an error loading this page",
  description = "If error persists, you can let us know about it by emailing support@withspiffy.com, or by sending us a note on the Contact Us page",
}) => (
  <Centered>
    <ErrorPaper>
      <BigOops sx={{ mb: -8 }} align="center">
        Error
      </BigOops>
      <HoldRobot>
        <RobotImg src={Robot} alt="funny looking robot" />
      </HoldRobot>
      <Typography variant="h6" align="center" sx={{ mb: 4 }}>
        {title}
      </Typography>
      <Typography variant="body1" align="center">
        {description}
      </Typography>
    </ErrorPaper>
  </Centered>
);

export default PageError;
