/* eslint-disable */
import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import { AlertCircle, CheckCircle } from "react-feather";
import styled from "styled-components/macro";
import useAllCountries from "../../../reactQueryHooks/useAllCountries";
import useStatesByCountry from "../../../reactQueryHooks/useStatesByCountry";
import { CustomTextField } from "../../../styles/forms";

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 12px;

  .MuiSvgIcon-root {
    height: 16px;
    width: 16px;
    margin-right: 0px;
    transform: translate(0px, 0px);
  }

  svg {
    height: 14px;
    width: 14px;
    margin-right: 4px;
    transform: translate(3px, -1px);
  }

  a {
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const CheckCircleGreen = styled(CheckCircle)`
  color: ${(props) => props.theme.palette.success.light};
`;

const StyledSelect = styled(Select)`
  .MuiSvgIcon-root {
    color: ${(props) => props.theme.palette.background.bw} !important;
  }
`;

const LoadingSpan = () => (
  <motion.span
    style={{
      fontSize: 10,
      fontWeight: "400",
      opacity: 1,
      color: "darkgrey",
      letterSpacing: 1,
      marginLeft: 6,
    }}
    animate={{ opacity: [0.8, 0.6, 0.4, 0.6, 0.8, 1] }}
    transition={{ duration: 1.4, repeat: Infinity }}
  >
    loading...
  </motion.span>
);

const FormThree = ({ dispatch, ACTIONS, formState }) => {
  const [selectedCountryOpen, setSelectedCountryOpen] = useState(false);
  const [selectedStateOpen, setSelectedStateOpen] = useState(false);

  const { newSelectedCountry, newSelectedState, newCompanyText } = formState;

  const { data: countries, isLoading: countriesLoading } = useAllCountries();

  const { data: states, isLoading: statesLoading } =
    useStatesByCountry(newSelectedCountry);

  return (
    <>
      <FlexDiv>
        <Typography variant="subtitle2">
          What Country Do You Work In?
        </Typography>
        {!countriesLoading && !newSelectedCountry && <AlertCircle />}
        {!countriesLoading && newSelectedCountry && <CheckCircleGreen />}
        {countriesLoading && <LoadingSpan />}
      </FlexDiv>
      <FormControl fullWidth>
        <InputLabel id="country-check" size="small">
          Country
        </InputLabel>
        <StyledSelect
          labelId="country-check"
          select
          onChange={(e) => {
            return dispatch({
              type: ACTIONS.SET_NEW_COUNTRY,
              payload: e.target.value,
            });
          }}
          open={selectedCountryOpen}
          onOpen={() => setSelectedCountryOpen(true)}
          onClose={() => setSelectedCountryOpen(false)}
          label="Country"
          fullWidth
          variant="outlined"
          disabled={countriesLoading}
          size="small"
          color="secondary"
          value={newSelectedCountry}
        >
          {countries?.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              {country.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>

      <FlexDiv>
        <Typography variant="subtitle2">
          What Province/State do you work in?
        </Typography>
        {!newSelectedState && !statesLoading && <AlertCircle />}
        {newSelectedState && !statesLoading && <CheckCircleGreen />}
        {statesLoading && <LoadingSpan />}
      </FlexDiv>
      <FormControl fullWidth>
        <InputLabel id="state-check" size="small">
          Province/State
        </InputLabel>
        <StyledSelect
          labelId="state-check"
          select
          open={selectedStateOpen}
          onChange={(e) =>
            dispatch({ type: ACTIONS.SET_NEW_STATE, payload: e.target.value })
          }
          onClose={() => setSelectedStateOpen(false)}
          onOpen={() => setSelectedStateOpen(true)}
          label="Province/State"
          fullWidth
          disabled={statesLoading}
          variant="outlined"
          size="small"
          color="secondary"
          value={newSelectedState}
        >
          {states?.map((state) => (
            <MenuItem value={state.id} key={state.id}>
              {state.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      <FlexDiv>
        <Typography variant="subtitle2">
          If you are part of a company, but can't find it, name it here.
        </Typography>
      </FlexDiv>
      <CustomTextField
        value={newCompanyText}
        onChange={(e) =>
          dispatch({
            type: ACTIONS.SET_NEW_COMPANY_TEXT,
            payload: e.target.value,
          })
        }
        rows={2}
        placeholder="Company Name"
      />
      <span style={{ fontSize: 10, color: "darkgrey", marginLeft: 4 }}>
        *optional
      </span>
    </>
  );
};

export default FormThree;
